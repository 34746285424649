.filter-selector
  border-bottom 1px solid gray2

.filter-selector__flyout
  flexx(row)

.filter-selector__fields
  margin-left 0.875rem
  width 12rem

.filter-selector__field-description
  color textLight

.filter-selector__ctrls
  display flex
  flex-flow row nowrap
  justify-content flex-end
  padding-right 2 * workspace-gutter

.filter-selector__input-container
  flexx(row)
  inset-shadow()
  margin 0.5rem 0
  border-radius 0.125rem

.filter-selector__input-container--invalid
  error-outline()

.filter-selector__container
  margin workspace-gutter
  margin-top 0

  label:first-of-type
    margin-top 0

.filter-selector__label
  display block
  margin 0 workspace-gutter

.filter-selector__range-input
  flex 1
  margin 0
  background transparent
  box-shadow none
  box-sizing border-box
  width 100%

.filter-selector__label--categorical
  cursor pointer
  display flex
  font-size 0.875rem
  margin 0
  padding 0.25rem workspace-gutter

  > *:first-child
    flex 1

  > *:last-child
    margin-left workspace-gutter

.filter-selector__over-category-limit-msg
  display flex
  font-size 0.875rem
  justify-content center
  margin 0.5rem 0.25rem 0.25rem
