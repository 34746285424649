.doc-text__match
  background-size .4rem 2px
  background-position 0 100%
  background-repeat repeat-x
  display inline
  font-weight bold
  padding 1px 0

.doc-text__match--conceptual
  background-image linear-gradient(to right, rgba(3, 150, 194, 0.8) 60%, rgba(3, 150, 194, 0) 60%)

.doc-text__match--exact
  background-image linear-gradient(to right, rgba(3, 150, 194, 0.8) 0%, rgba(3, 150, 194, 0.8) 100%)


.doc-text__match-positive {
  background-color: rgba(73, 192, 236, 0.3);
}

.doc-text__match-negative {
  background-color: rgba(222, 94, 113, 0.3);
}

.doc-text__match-neutral {
  background-color: rgba(227, 227, 158, 0.3);
}


.doc-text__match-positive,
.doc-text__match-negative,
.doc-text__match-neutral {
  filter: saturate(2.5);
}
